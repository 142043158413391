<template>
  <aside class="dashboard-sidebar">
    <div class="dashboard-sidebar-top">
      <header class="dashboard-sidebar-top-header">
        <div class="logo">
          <img src="../../assets/totem-logo.png" alt="">
        </div>
      </header>
      <router-link to="/admin" class="sidebar-button">
        <div class="sidebar-icon">
          <i class="far fa-home fa-lg"></i>
        </div>
        <span>Dashboard</span>
      </router-link>
      <router-link to="/admin/events" class="sidebar-button">
        <div class="sidebar-icon">
          <i class="far fa-calendar-alt fa-lg"></i>
        </div>
        <span>Events</span>
      </router-link>
      <router-link to="/admin/games" class="sidebar-button">
        <div class="sidebar-icon">
          <i class="far fa-gamepad-alt fa-lg"></i>
        </div>
        <span>Games</span>
      </router-link>
    </div>
    <div class="dashboard-sidebar-bottom">
      <div class="sidebar-button" @click="signout">
        <div class="sidebar-icon">
            <i class="far fa-sign-out fa-lg"></i>
        </div>
        <span>Sign out</span>
      </div>
    </div>
  </aside>
</template>

<script>
export default {
  name: 'AdminSidebar',
  props: {
    active: {
      type: String,
      default: 'home'
    },
  },
  data: () => {
    return {
      sidebarEl: null,
    }
  },
  components: {},
  mounted() {
    this.sidebarEl = document.querySelector('.dashboard-sidebar')
  },
  methods: {
    signout() {
      this.$store.dispatch("logout").then(result => {
        if (result === true) {
          this.$router.push('/')
        } else {
          this.createNotification({
            type: 'error',
            title: 'Error during sign out',
            message: `An error occured while signing out: ${result}`
          })
        }
      }).catch(error => {
        console.log(`Signout error that should've been caught in the store action: ${error}`)
      })
    },
    toggleSidebar() {

    }
  },
}
</script>

<style lang="scss">
.dashboard-sidebar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 5rem;
  background-color: var(--color-bg);
  border-right: 2px solid #445;
  padding: 2rem 0;

  &.expanded {
    width: auto;
    align-items: flex-start;
    padding: 2rem 2.5rem;

    .sidebar-button {
      width: 200px;
    }

    .sidebar-icon + span {
      display: block;
    }

    .dashboard-sidebar-top-header {
      border-bottom: 2px solid var(--color-bg-light);
    }
  }

  &-top {
    display: flex;
    flex-direction: column;

    &-header {
      padding-bottom: 1rem;
      margin-bottom: 2rem;

      img {
        height: 2.5rem;
      }
    }
  }

  .sidebar-button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: rgba(#A19EC1, 0.5);
    text-decoration: none;
    border-radius: 10px;
    cursor: pointer;
    transition: color 200ms ease;

    .sidebar-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3rem;
      height: 3rem;
      background-color: rgba(#A19EC1, 0.2);
      border-radius: 10px;
    }

    .sidebar-icon + span {
      margin: 0 1rem;
      display: none;
    }

    &:hover {
      color: rgba(#A19EC1, 1);
    }

    & + .sidebar-button {
      margin-top: 1rem;
    }

    &.router-link-active {
      color: #C998EE;

      .sidebar-icon {
        background-color: rgba(#8D3EC9, 0.5);
      }
    }
  }
}
</style>