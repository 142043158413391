<template>
  <div class="dashboard">
    <AdminSidebar />
    <main class="dashboard-content">
      <slot></slot>
    </main>
  </div>
</template>

<script>
import AdminSidebar from '@/components/admin/AdminSidebar'

export default {
  name: 'AdminOverview',
  components: {
    AdminSidebar,
  },
}
</script>

<style lang="scss">
.dashboard {
  display: flex;
  flex-shrink: 1;
  height: 100vh;
  overflow: hidden;

  &-content {
    width: 100%;
    padding: 2rem 3rem;
    overflow: auto;

    .dashboard-title {
      margin-bottom: 2rem;
    }

    .content-block {
      background-color: var(--color-bg);
      border-radius: 18px;
      padding: 2rem;

      .block-title {
        margin-bottom: 1rem;

        &.space-between {
          display: flex;
          justify-content: space-between;
          align-items: center;

          i+i {
            margin-left: 1rem;
          }
        }

        .title-icon {
          color: var(--color-text-light);
          transition: color 150ms ease;
          cursor: pointer;

          &:hover {
            color: #fff;
          }

          &.disabled {
            color: rgba(#A19EC1, 0.1);
            cursor: default;

            &:hover {
              color: rgba(#A19EC1, 0.1);
            }
          }
        }
      }
    }
  }

  .button {
    cursor: pointer;
  }
}
</style>