<template>
  <div class="content-block models-list">
    <div class="block-title space-between">
      <h2>{{ title }}</h2>
      <i class="fas fa-plus title-icon" :title="`Add new ${modelName}`" @click="this.$emit('addObject')"></i>
    </div>
    <table>
      <thead>
        <tr>
          <th v-for="field in fields" :key="field.key">{{ field.label }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="object in objects" :key="object.id" @click="this.$emit('selectObject', object[uidField])" :class="{ active: selectedObject && object[uidField] === selectedObject[uidField]}">
          <td v-for="field in fields" :key="field.key">{{ field.format ? field.format(object[field.key]) : object[field.key] }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "ModelsList",
  props: {
    title: {
      type: String,
      required: true,
    },
    modelName: {
      type: String,
      required: true,
    },
    objects: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    uidField: {
      type: String,
      required: false,
      default: 'id',
    },
    selectedObject: {
      type: Object,
      required: false,
      default: null,
    },
  },
  emits: ['selectObject', 'addObject'],
}
</script>

<style lang="scss">
.models-list {
  table {
    width: 100%;
    text-align: left;
    font-size: 15px;
    border-collapse: collapse;

    tbody tr {
      --tr-radius: 10px;
      cursor: pointer;

      td:first-child {
        border-radius: var(--tr-radius) 0 0 var(--tr-radius);
      }

      td:last-child {
        border-radius: 0 var(--tr-radius) var(--tr-radius) 0;
      }

      &:hover {
        background-color: rgba(#A19EC1, 0.07);
      }

      &.active {
        background-color: rgba(#A19EC1, 0.15);
        td { color: #c8c6d0; }
      }
    }

    th,
    td {
      padding: 1rem;
    }

    th {
      font-weight: normal;
    }

    tbody td {
      color: var(--color-text-light);
    }
  }
}
</style>