<template>
  <AdminOverview>
    <h1 class="dashboard-title">Manage events</h1>
    <EventsModule />
  </AdminOverview>
</template>

<script>
import AdminOverview from '@/components/admin/AdminOverview'
import EventsModule from '@/components/admin/EventsModule'

export default {
  name: 'Dashboard',
  components: {
    AdminOverview,
    EventsModule,
  },
}
</script>