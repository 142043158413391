<template>
  <form class="event-editor">
    <div class="block-title space-between">
      <h2>Selected event</h2>
      <div>
        <i class="fas fa-undo-alt title-icon" title="Reset to saved values"
          v-if="selectedEvent && selectedEvent.id !== 'temp'" @click="resetEvent"></i>
        <i class="fas fa-trash title-icon" title="Delete event" v-if="selectedEvent" @click="removeEvent"></i>
      </div>
    </div>

    <div class="event-editor-grid" v-if="selectedEvent">
      <div :class="['field', 'event-name', nameClassObject]">
        <input type="text" name="name" id="eName" v-model="selectedEvent.eventName" required>
        <label for="name">Name</label>
      </div>

      <div :class="['field', 'event-description', descriptionClassObject]">
        <textarea name="description" id="eDescription" v-model="selectedEvent.eventDescription"></textarea>
        <label for="description">Description</label>
      </div>

      <div :class="['field', 'event-startdate', eventStartClassObject]">
        <input type="text" name="name" id="eStartDate" v-model="selectedEvent.eventStartDate" required>
        <label for="name">Startdate</label>
      </div>

      <div :class="['field', 'event-enddate', eventEndClassObject]">
        <input type="text" name="name" id="eEndDate" v-model="selectedEvent.eventEndDate" required>
        <label for="name">Enddate</label>
      </div>

      <div :class="['field', 'event-location', locationClassObject]">
        <input type="text" name="name" id="eLocation" v-model="selectedEvent.location" required>
        <label for="name">Location</label>
      </div>

      <div :class="['field', 'event-tag', tagClassObject]">
        <input type="text" name="name" id="eTags" v-model="selectedEvent.tag">
        <label for="name">Tag</label>
      </div>

      <div :class="['field', 'event-link', linkClassObject]">
        <input type="text" name="name" id="eLink" v-model="selectedEvent.link">
        <label for="name">Link</label>
      </div>
    </div>

    <input class="button" type="submit" :value="selectedEvent.id === 'temp' ? 'Create event' : 'Save event'"
      v-if="selectedEvent" @click="saveEvent">

    <i class="hint" v-else>Please select an event to edit</i>
  </form>
</template>

<script>
export default {
  name: "EventEditor",
  props: ['selectedEvent', 'listEvent'],
  emits: ['saveEvent', 'resetEvent', 'removeEvent'],
  computed: {
    nameClassObject() {
      return { 'changed' : this.listEvent === null || this.selectedEvent.eventName !== this.listEvent.eventName }
    },
    descriptionClassObject() {
      return { 'changed': this.listEvent === null || this.selectedEvent.eventDescription !== this.listEvent.eventDescription }
    },
    eventStartClassObject() {
      return { 'changed' : this.listEvent === null || this.selectedEvent.eventStartDate !== this.listEvent.eventStartDate }
    },
    eventEndClassObject() {
      return { 'changed' : this.listEvent === null || this.selectedEvent.eventEndDate !== this.listEvent.eventEndDate }
    },
    locationClassObject() {
      return { 'changed' : this.listEvent === null || this.selectedEvent.location !== this.listEvent.location }
    },
    linkClassObject() {
      return { 'changed' : this.listEvent === null || this.selectedEvent.link !== this.listEvent.link }
    },
    tagClassObject() {
      return { 'changed' : this.listEvent === null || this.selectedEvent.tag !== this.listEvent.tag }
    }
  },
  methods: {
    saveEvent(e) {
      this.$emit('saveEvent', e)
    },
    resetEvent() {
      this.$emit('resetEvent', this.selectedEvent.id)
    },
    removeEvent() {
      this.$emit('removeEvent', this.selectedEvent.id)
    },
    pad(num, padlen, padchar) {
      var pad_char = typeof padchar !== 'undefined' ? padchar : '0';
      var pad = new Array(1 + padlen).join(pad_char);
      return (pad + num).slice(-pad.length);
    },
  }
}
</script>

<style lang="scss">
.event-editor {
  &-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem 2rem;
  }

  .field {
    display: flex;
    flex-direction: column-reverse;

    &.event-name {
      grid-column: 1 / span 2;
    }

    &.event-description {
      grid-column: 1 / span 2;
    }

    &.changed label {
      font-weight: 500;
      color: #ccc;
    }

    label, input {
      display: block;
    }

    label {
      font-size: 14px;
      margin-bottom: 8px;
      color: var(--color-text-light);
    }

    input:required + label::after,
    textarea:required + label::after {
      content: '*';
      vertical-align: text-top;
      color: var(--color-red);
      margin-left: 2px;
    }
  }

  input[type=text], textarea {
    width: 100%;
    outline: none;
    color: #fff;
    font: 17px "Rubik", sans-serif;
    transition: background-color 200ms ease, border-color 200ms ease;
    background-color: transparent;
    border: 2px solid #817F98;
    padding: .5rem 1rem;
    border-radius: 1000px;
    resize: none;

    &::placeholder {
      color: #817F98;
    }

    &:focus, &:hover {
      border-color: #fff;
    }

    &:disabled {
      background-color: #817F98;
      color: #fff;

      &::placeholder {
        color: #817F98;
      }

      &:focus, &:hover {
        border-color: #817F98;
      }
    }
  }

  textarea {
    border-radius: 16px;
    min-height: 130px;
  }

  .button {
    width: 100%;
    border-radius: 1000px;
    text-align: center;
    margin-top: 2rem;
  }

  .hint {
    color: var(--color-text-light);
  }
}
</style>