<template>
  <div class="events-module">
    <ModelsList
      :title="'Events'"
      :modelName="'event'"
      :objects="cEvents"
      :fields="[
        { key: 'eventName', label: 'Name' },
        { key: 'location', label: 'Location' },
        { key: 'eventStartDate', label: 'Startdate', format: formatDateString },
        { key: 'eventEndDate', label: 'Enddate', format: formatDateString },
      ]"
      :uidField="'id'"
      :selectedObject="selectedEvent"
      @selectObject="selectEvent"
      @addObject="initCreateEvent" />
    <EventEditor
      class="content-block"
      :selectedEvent="selectedEvent"
      :listEvent="listEvent"
      @saveEvent="saveEvent"
      @resetEvent="resetEvent"
      @removeEvent="removeEvent" />
  </div>
</template>

<script>
import { getEventsList, getEvent, updateEvent, createEvent, deleteEvent } from '@/firebase'
import ModelsList from '@/components/admin/ModelsList'
import EventEditor from '@/components/admin/EventEditor'
import { formatDateString, stringToDate, dateToString, validURL } from '@/util'

export default {
  name: "EventsModule",
  inject: ["createNotification", "createDialog"],
  components: {
    ModelsList,
    EventEditor
  },
  data() {
    return {
      events: [],
      selectedEvent: null,
      listEvent: null,
      noConfirm: false,
    }
  },
  computed: {
    cEvents() {
      return this.events.sort((a, b) => stringToDate(b.eventStartDate) - stringToDate(a.eventStartDate))
    },
    formatDateString() {
      return formatDateString
    },
    changes() {
      return this.selectedEvent !== null ? this.eventHasChanges(this.selectedEvent) : []
    },
    showChangeDialog() {
      // Check if user prefs don't indicate a skip, an event is currently selected
      // and that event in question has changes
      if (!this.noConfirm && this.selectedEvent && (this.changes === true || this.changes.length !== 0)) {
        return true
      } else {
        return false
      }
    },
    unsavedChangesMessage() {
      let changesListBuilder = ''
      if (this.changes !== true) {
        for (const change of this.changes) {
          changesListBuilder += `<li>${change}</li>`
        }
      }
      return this.changes === true ?
        `<span>You didn't save this newly created event. All changes will be lost.</span>` :
        `<span>Your following changes will be lost:</span><br /><ul style="display: inline-block; padding: 0; text-align: left;">${changesListBuilder}</ul>`
    }
  },
  methods: {
    onDataChange(items) {
      let events = []

      items.forEach(item => {
        events.push({
          id: item.id,
          eventName: item.name,
          eventDescription: item.description,
          eventStartDate: dateToString(item.startDate.toDate()),
          eventEndDate: dateToString(item.endDate.toDate()),
          location: item.location,
          tag: item.tag ? item.tag : undefined,
          link: item.link ? item.link : undefined
        })
      })

      this.events = events
    },
    eventHasChanges(event) {
      const listEvent = this.events.find(e => e.id === event.id)
      if (listEvent === undefined) {
        // The event does not exist in the list. This is a newly created event, all fields are lost changes.
        return true
      } else {
        let changes = []
        
        // Loop over the object's props and add all changes to the list of changes
        for (const key in event) {
          if (event[key] !== listEvent[key]) {
            // Add change to the list, with a custom name based on the changed prop
            let changeName
            switch (key) {
              case 'eventName':
                changeName = 'Name'
                break;
              case 'eventDescription':
                changeName = 'Description'
                break;
              case 'eventStartDate':
                changeName = 'Start date'
                break;
              case 'eventEndDate':
                changeName = 'End date'
                break;
              case 'location':
                changeName = 'Location'
                break;
              case 'tag':
                changeName = 'Tag'
                break;
              case 'link':
                changeName = 'Link'
                break;            
              default:
                changeName = 'Unknown'
                break;
            }
            changes.push(changeName)
          }
        }

        return changes
      }
    },
    selectEvent(id, confirmedPopup) {
      // Only show dialog if unsaved changes are present
      if (!confirmedPopup && this.showChangeDialog) {
        // Display popup and on confirm, re-call method with confirmedPopup true
        this.createDialog({
          type: 'alert-warning',
          title: 'Select other event?',
          message: this.unsavedChangesMessage,
          options: [
            {
              type: 'cancel',
              text: 'Cancel',
              callback: () => {}
            },
            {
              type: 'confirm',
              text: 'Select event',
              callback: () => this.selectEvent(id, true)
            }
          ]
        })
      } else {
        // Select the new event as a deep clone
        this.selectedEvent = { ...this.events.find(e => e.id === id) }
        this.listEvent = { ...this.events.find(e => e.id === id) }
      }
    },
    initCreateEvent(confirmedPopup) {
      // Only show dialog if unsaved changes are present
      if (!confirmedPopup && this.showChangeDialog) {
        // Display popup and on confirm, re-call method with confirmedPopup true
        this.createDialog({
          type: 'alert-warning',
          title: 'Select other event?',
          message: this.unsavedChangesMessage,
          options: [
            {
              type: 'cancel',
              text: 'Cancel',
              callback: () => {}
            },
            {
              type: 'confirm',
              text: 'Select event',
              callback: () => this.initCreateEvent(true)
            }
          ]
        })
      } else {
        // Create a new local event in the selectedEvent variable
        this.selectedEvent = {
          id: 'temp',
          eventName: '',
          eventDescription: '',
          eventStartDate: dateToString(new Date()),
          eventEndDate: dateToString(new Date()),
          location: '',
          tag: '',
          link: ''
        }
      }
    },
    resetEvent(id) {
      getEvent(id).then(dbEvent => {
        if (dbEvent) {
          this.selectedEvent.id = dbEvent.id
          this.selectedEvent.eventName = dbEvent.name
          this.selectedEvent.eventDescription = dbEvent.description
          this.selectedEvent.eventStartDate = dateToString(dbEvent.startDate.toDate())
          this.selectedEvent.eventEndDate = dateToString(dbEvent.endDate.toDate())
          this.selectedEvent.location = dbEvent.location
          this.selectedEvent.tag = dbEvent.tag ? dbEvent.tag : undefined
          this.selectedEvent.link = dbEvent.link ? dbEvent.link : undefined
        } else {
          console.error("Event could not be found in DB, can't reset")
        }
      }).catch(error => {
        console.error(error)
      })
    },
    saveEvent(e) {
      e.preventDefault()
      const event = this.selectedEvent
      const selectedStartDateString = document.getElementById('eStartDate').value
      const selectedEndDateString = document.getElementById('eEndDate').value
      
      // Event data validation
      try {
        // Required fields validation
        const form = document.querySelector('form.event-editor')
        const reqFields = form.querySelectorAll('[required]')
        reqFields.forEach(field => {
          if (field.value == null || field.value == '') {
            field.focus()
            throw 'You forgot to enter a required field'
          }
        })

        // Link validation: Not a valid link
        if (event.link !== '' && event.link !== undefined) {
          if (!validURL(event.link)) {
            throw 'The given link is invalid'
          }
        }

        const eventObject = {
          name: event.eventName,
          description: event.eventDescription,
          location: event.location,
          startDate: stringToDate(selectedStartDateString),
          endDate: stringToDate(selectedEndDateString),
        }

        // Date validation: Invalid format
        if (!(eventObject.startDate instanceof Date)) {
          throw 'Start date is not a valid date!'
        } else if (!(eventObject.endDate instanceof Date)) {
          throw 'End date is not a valid date!'
        }

        // Date validation: End date before start date
        if (eventObject.endDate - eventObject.startDate < 0) {
          throw 'The end date cannot be before the start date'
        }

        // Set optional fields
        if (event.link !== undefined) {
          eventObject.link = event.link
        }

        if (event.tag !== undefined) {
          eventObject.tag = event.tag
        }
        
        // Attempt to update the DB
        try {
          // If the event is a new event that doesn't exist yet in the DB, create it in the DB and store its ID
          if (event.id === 'temp') {
            createEvent(eventObject).then(result => {
              event.id = result

              // Succesfully created the event in the database, store it in the list too
              this.events.push({ ...event })
              this.listEvent = { ...event }

              this.createNotification({
                type: 'confirm',
                title: `Success`,
                message: `Event created succesfully!`
              })
            }).catch(e => {
              this.createNotification({
                type: 'error',
                title: 'Error creating the event',
                message: e,
                duration: 10
              })
            })
          } else {
            // Update the event with the given ID with the new data in the DB
            updateEvent(event.id, eventObject)

            // Succesfully updated the event in the database, store it in the list too
            let eIndex = this.events.findIndex(e => e.id === event.id)
            this.events[eIndex] = { ...event }
            this.listEvent = { ...event }

            this.createNotification({
              type: 'confirm',
              title: `Success`,
              message: `Event saved succesfully!`
            })
          }
        } catch (e) {
          this.createNotification({
            type: 'error',
            title: 'Error during saving',
            message: e,
            duration: 10
          })
        }

      } catch (e) {
        this.createNotification({
          type: 'error',
          title: 'Error during validation',
          message: e,
          duration: 10
        })
      }
    },
    removeEvent(id, confirmedPopup) {
      if (!confirmedPopup) {
        // Display popup and on confirm, re-call method with confirmedPopup true
        this.createDialog({
          type: 'alert-warning-remove',
          title: 'Delete event?',
          message: 'This action cannot be undone',
          options: [
            {
              type: 'cancel',
              text: 'Cancel',
              callback: () => {}
            },
            {
              type: 'confirm',
              text: 'Delete event',
              callback: () => this.removeEvent(id, true)
            }
          ]
        })
      } else {
        // Remove the event
        getEvent(id).then(dbEvent => {
          if (dbEvent) {
            // If event is in the DB, remove it from the DB first before removing it locally
            deleteEvent(id).then(() => {
              this.deleteLocalEvent(id)
            })
          } else {
            // If event is not in the DB, remove it only locally (if it's in the list)
            if (this.listEvent !== null) this.deleteLocalEvent(id)
          }
          this.selectedEvent = null
          this.listEvent = null
          this.createNotification({
            type: 'confirm',
            title: `Deleted event`,
            message: `Event has been succesfully deleted`
          })
        }).catch(e => {
          this.createNotification({
            type: 'error',
            title: `Error deleting event`,
            message: e.message
          })
        })
      }
    },
    deleteLocalEvent(id) {
      // Remove the element from the local events list
      const eIndex = this.events.findIndex(e => e.id === id)
      if (eIndex !== -1) {
        this.events.splice(eIndex, 1)
      } else {
        console.error('This event was not found in the local events list. Please try reloading the page or contact a moderator.')
      }
    },
  },
  mounted() {
    getEventsList(list => {
      this.onDataChange(list)
    })
  },
}
</script>

<style lang="scss">
.events-module {
  display: flex;
  align-items: flex-start;

  .models-list {
    flex: 1;
  }
  .event-editor {
    flex-basis: 500px;
    margin-left: 2rem;
  }
}
</style>